import * as $ from 'jquery';

require('jquery.marquee');

$(() => {
  initHeader();
  initMarquee();
  initFAQ();
});

function initHeader() {
  var header = $('.header'), nav = $('.header__nav', header), body = $('body'), win = $(window);

  function preventMotion(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  $('.header__menu .dropdown', header).each(function () {
    var dropdown = $(this);
    dropdown.children('a').on('click', function () {
      dropdown.toggleClass('active');
      return false;
    });
  });

  $('.header__lang ', header).each(function () {
    var lang = $(this);
    lang.find('li[data-lang="' + $('html').attr('lang') + '"]').addClass('active');
    lang.find('li.active > a').on('click', function () {
      lang.toggleClass('active');
      return false;
    });
  });

  $('.header__toggle, .header__close', header).on('click', function () {
    if (nav.hasClass('visible')) {
      setTimeout(function () {
        nav.removeClass('open');
        body.removeClass('menu-open');
        win.off('scroll', preventMotion);
        win.off('touchmove', preventMotion);
        setTimeout(function () {
          nav.removeClass('visible');
        }, 250);
      }, 25);
    } else {
      nav.addClass('visible');
      setTimeout(function () {
        nav.addClass('open');
      }, 25);
      setTimeout(function () {
        body.addClass('menu-open');
        win.on('scroll', preventMotion, false);
        win.on('touchmove', preventMotion, false);
      }, 300);
    }
    return false;
  });
}

function initMarquee() {
  $('.marquee').each(function () {
    $('.marquee__space', this).marquee({
      duration: 10000,
      gap: 0,
      delayBeforeStart: 0,
      direction: 'left',
      duplicated: true
    });
  });
}

function initFAQ() {
  var faq = $('.faq');
  $('.faq__item', faq).each(function () {
    var q = $(this);
    $('.faq__question > a', q).on('click', function () {
      $('.faq__answer', q).slideToggle(400);
      q.toggleClass('active');
      return false;
    });
  });
}
